import * as React from 'react';
import { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';
import { URL_API } from 'gatsby-env-variables';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { convertToBgImage } from 'gbimage-bridge';
import '../account/styles.css';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import { useAuth } from '@app/contexts/MainAppContext';

const SubscriptionPage = () => {
  const { userData } = useAuth();
  const [subscriptionEnd, setSubscriptionEnd] = useState();
  const [isOpenCancel, setIsOpenCancel] = useState(false);

  function closeModalCancel() {
    setIsOpenCancel(false);
  }

  function openModalCancel() {
    setIsOpenCancel(true);
  }

  const cancelSubscription = async () => {
    const response = await fetch(`${URL_API}/user/${userData.id}/subscription/cancel`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
      body: JSON.stringify({ id: userData.id }), // body data type must match "Content-Type" header
    });

    const json = await response.json();

    if (json.status === 'Success') {
      alert('Subscription Cancelled');
      window.location.reload();
    } else {
      alert('Cancellation Failed');
    }
  };

  // useEffect(() => {
  //   if (formData.payment_start) {
  //     let payment_end = Moment(formData.payment_end).format('MM-DD-YYYY');
  //     if (formData.payment_type == 'Monthly') {
  //       setSubscriptionEnd(payment_end);
  //     } else if (formData.payment_type == 'Yearly') {
  //       setSubscriptionEnd(payment_end);
  //     } else {
  //       setSubscriptionEnd('');
  //     }
  //   }
  // }, [formData.payment_start]);

  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "bk_starts.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  const bg_login = getImage(data.login_bg);
  const loginBg = convertToBgImage(bg_login);

  return (
    <LayoutPrivate pageTitle="Manage Subscription">
      <BackgroundImage Tag="section" className="bg-bottom-footer " style={{ position: 'inherit' }} {...loginBg}>
        <section className="section-secondary relative w-full min-h-[100vh] content-subcription">
          <div className="max-w-[1000px] mx-auto grid grid-cols-1 pt-10 xl:px-20 px-5">
            {userData?.payment_type !== 'Free' ? (
              <Transition appear show={isOpenCancel} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModalCancel}>
                  <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                      &#8203;
                    </span>

                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95">
                      <div className="inline-block w-full max-w-xl py-10 xl:px-12 px-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <div>
                          <IoMdClose className="ml-auto w-5 h-5 text-black cursor-pointer" onClick={closeModalCancel} />
                        </div>
                        <div className="mt-2 text-center">
                          <p className="font-telex font-2xl font-medium mb-5">Are you sure do you want to cancel your subscription?</p>
                          <div className="text-center">
                            <button
                              onClick={cancelSubscription}
                              className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl mx-2 px-4 py-2 mb-5 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500 w-1/4">
                              Yes
                            </button>
                            <button
                              onClick={closeModalCancel}
                              className="uppercase border-orange-150 rounded-md border-4 text-brown-50 font-rokkit text-xl mx-2 px-4 py-2 font-bold bg-white-rock  shadow-inner2 hover:bg-limed-oak-550 transition-all duration-500 w-1/4">
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition>
            ) : null}

            <div className="pt-10 pb-5 xl:mb-20 mb-10 mt-5 xl:px-10 px-5 bg-white rounded-xl text-grey">
              <div className="secondary-page-content content-account">
                <div className="justify-items-center col-span-12 bg-white rounded-xl">
                  <div className="xl:grid grid-cols-12">
                    <div className="col-span-12 xl:px-8 xl:text-left text-center">
                      <h1 className="profile-title font-rockwell-bold font-bold mb-5">Manage subscription</h1>
                    </div>
                    <div className="avatar xl:block xl:flex-start flex flex-center align-items-center flex-col col-span-12 pb-10 px-8">
                      <div className="grid grid-cols-10 align-items-center pb-5">
                        <div className="xl:col-span-2 text-center col-span-3  mr-5 ">
                          <div className="inline-block align-middle rounded-full overflow-hidden">
                            {userData?.avatar ? (
                              <img
                                src={userData?.avatar}
                                formats={['auto', 'webp', 'avif']}
                                width={91}
                                alt="avatar image"
                                placeholder="blurred"
                              />
                            ) : (
                              <StaticImage
                                src={'../../images/account-avatar-img.png'}
                                formats={['auto', 'webp', 'avif']}
                                width={91}
                                height={91}
                                alt="avatar image"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-span-8 text-center  xl:text-left ">
                          <p className="subs-name font-opensans-bold font-2xl font-bold">{userData?.name}</p>
                          <p className="subs-mail font-opensans font-semibold text-sm">{userData?.email}</p>
                        </div>
                      </div>
                      <h3 className="font-opensans subs-subtitle mt-10">Subscription</h3>
                      <div className="list-divider mb-4" />
                      {userData?.role === 'Admin' ? (
                        <div className="col-span-12 w-full my-7 max-w-3xl mx-auto rounded-md">
                          <div className="grid grid-cols-12">
                            <div className="col-span-6">
                              <h3 className="font-opensans  text-lg font-semibold text-outer-space">Administrator User</h3>
                            </div>
                            <div className="col-span-6">
                              <div className="active-indicator p-1 border xl:w-20 w-auto ml-auto text-center rounded-md">
                                <p className="font-opensans text-base font-normal text-outer-space">Active</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : userData?.payment_type !== 'Free' && userData?.payment_status === 'Active' ? (
                        <>
                          <div className="col-span-12 w-full mx-auto rounded-md">
                            <div className="grid grid-cols-12">
                              <div className="col-span-6">
                                <h3 className="font-opensans  text-lg font-semibold text-subscribe">{userData?.payment_type}</h3>
                                <p className="font-opensans text-subscribe font-normal text-#848789">Until {subscriptionEnd}</p>
                              </div>
                              <div className="col-span-6">
                                <div className="active-indicator p-1 border xl:w-20 w-auto ml-auto text-center rounded-md">
                                  <p className="font-opensans text-base font-normal text-outer-space">
                                    {userData?.payment_status || 'none'}
                                  </p>
                                </div>
                              </div>
                              <div className="col-span-12 py-2 mt-3">
                                <div className="grid grid-cols-12">
                                  <div className="col-span-6">
                                    <p className="text-outer-space font-base font-opensans font-normal">Next payment</p>
                                  </div>
                                  <div className="col-span-6 text-right">
                                    <p className="text-outer-space font-base font-opensans font-normal">{subscriptionEnd}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-12 text-center mt-5">
                            <Link to="/pricing-guide">
                              <button className="button-profile font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase">
                                Change Subscription
                              </button>
                            </Link>
                          </div>
                          <div className="col-span-12 text-left mt-5">
                            <p className="underline rounded-md font-opensans font-bold" onClick={openModalCancel}>
                              Cancel Subscription
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-span-12 w-full max-w-3xl mx-auto rounded-md">
                            <div className="grid grid-cols-12">
                              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                <h3 className="text-subscribe font-opensans text-lg">Free Subscription</h3>
                              </div>
                              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                <div className="active-indicator p-1 border xl:w-20 w-auto ml-auto text-center rounded-md">
                                  <p className="font-opensans text-black text-base font-normal">Active</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-12 mt-10 text-center mt-5">
                            <Link to="/pricing-guide">
                              <button className="button-profile font-opensans full-width text-center pt-2 pb-2 text-white color-white uppercase btn-round">
                                Upgrade Your Plan Now
                              </button>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
        </section>
      </BackgroundImage>
    </LayoutPrivate>
  );
};
export default SubscriptionPage;
